@import url("https://rsms.me/inter/inter.css");

@supports (font-variation-settings: normal) {
  html {
    font-family: "Inter var", sans-serif;
  }
}

@font-face {
  font-family: "ArgentCF";
  src: local("ArgentCF"),
    url(./assets/fonts/ArgentCF-Regular.woff2) format("opentype");
}

@font-face {
  font-family: "ArgentCF";
  src: local("ArgentCF"),
    url(./assets/fonts/ArgentCF-Bold.woff2) format("opentype");
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Inter", sans-serif;
  box-sizing: border-box;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.font-argent {
  font-family: "ArgentCF" !important;
}

.responsive-image {
  max-width: 800px;
}

@media screen and (max-width: 1330px) {
  .responsive-image {
    max-width: 600px;
  }
}

@media screen and (max-width: 1130px) {
  .responsive-image {
    max-width: 500px;
  }
}

@media screen and (max-width: 900px) {
  .responsive-image {
    max-width: 400px;
  }
}

.responsive-card {
  min-width: 500px;
  min-height: 500px;
}

@media only screen and (max-width: 600px) {
  .responsive-image {
    max-width: 340px;
    /* max-height: 340px; */
  }

  .responsive-card {
    min-width: 340px;
    min-height: 500px;
  }
}

.sidebarlist:hover .sidebarlist-button--icon {
  color: #000032 !important;
}

.active .sidebarlist {
  background: #c8ec82 !important;
  color: #000032 !important;
}

.active .sidebarlist-button--icon {
  color: #000032 !important;
}

.dashboard-responsive-image {
  max-width: 300px;
  max-height: 300px;
}

.image-responsive {
  max-width: 100%;
  height: auto;
  padding: 0;
  margin: 0;
}

.react-tel-input .form-control:focus {
  border-color: #000032 !important;
  box-shadow: none !important;
}

.background-blur {
  filter: blur(2px);
  pointer-events: none;
}

.lh-base {
  line-height: 1.5 !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.text-decoration-none {
  text-decoration: none !important;
}
